<template>
  <div style="height: 100%">
    <el-empty v-if="isShow" description="未见告警" style="width: 100%; height: 100%"></el-empty>
    <div v-else id="echartFive" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  watch: {
    id(newVal) {
      this.getData(newVal);
    },
  },
  data() {
    return {
      echartData: [],
      isShow: false,
    };
  },

  methods: {
    getData(id) {
      this.$http.get(`periodicInspectionBill/count/workOrder/${id}`).then((res) => {
        this.echartData = res.data;
        let result = this.echartData[1].some((item) => item);
        let resultTwo = this.echartData[2].some((item) => item);
        if (result || resultTwo) {
          this.isShow = false;
        } else {
          this.isShow = true;
        }
        this.initCharts();
      });
    },

    initCharts() {
      let myChart = this.$echarts.init(document.getElementById("echartFive"));
      let option = {
        title: {
          subtext: "单位：次"
        },
        legend: {
          bottom: 20,
        },
        tooltip: {},
        xAxis: [
          {
            data: this.echartData[0],
            axisLine: {
              lineStyle: {
                color: "#808080"
              }
            },
          },
        ],
        yAxis: [
          {
            minInterval: 1,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: "困人",
            type: "bar",
            itemStyle: {
              color: "#FF7E48",
            },
            emphasis: {
              itemStyle: {
                color: "#FF9265" // 设置鼠标移入时的背景颜色
              }
            },
            data: this.echartData[1],
          },
          {
            name: "停梯",
            type: "bar",
            itemStyle: {
              color: "#0747FD",
            },
            emphasis: {
              itemStyle: {
                color: "#2E64FD" // 设置鼠标移入时的背景颜色
              }
            },
            data: this.echartData[2],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
