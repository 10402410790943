<template>
  <div v-loading="loading" class="content">
    <div class="top">
      <div class="left">
        <div style="display: flex;align-items: center;height: 100%;font-size: 12px;color: #4D4D4D;">
          <img src="../../../assets/log.png" style="height: 40px;" alt="" srcset=""/>
          <span>日周月监管 /
            <span v-if="type == 1">周排查 / {{ elevotor.elevatorNO }}</span>
            <span v-if="type == 2">月调度 / {{ elevotor.elevatorNO }}</span>
          </span>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="elevatorInfo">
      <span style="color: #0747FD;">{{ elevotor.elevatorName ? elevotor.elevatorName + ' / ' : '' }}
        <span v-if="type == 1">周排查报告</span>
        <span v-if="type == 2">月调度报告</span>
      </span>
      <span style="font-size: 12px;margin-left: 10px;color: #4D4D4D;">{{ elevotor.startDate }} 至 {{ elevotor.endDate }}</span>
      <span style="font-size: 12px;margin-left: 10px;color: #4D4D4D;">{{ "(生成时间：" + elevotor.createTime ? elevotor.createTime : '' + ")"
      }}</span>
      <div class="link" v-if="type == 1">
        <router-link style="color: #fff; font-size: 14px" :to="'/weekCheck/' + elevotor.id" target="_blank">详细报告
          ></router-link>
      </div>
      <div class="link" v-if="type == 2">
        <router-link style="color: #fff; font-size: 14px" :to="'/monthDispatch/' + elevotor.id" target="_blank">详细报告
          ></router-link>
      </div>
    </div>
    <div class="echarts">
      <div class="item">
        <div class="itemTop">
          <div class="title">运行次数</div>
        </div>
        <div class="itemBottom">
          <RunDegree :type="type" :time="echartData[0]" :echartData="echartData[2]"></RunDegree>
        </div>
      </div>
      <div class="item">
        <div class="itemTop">
          <div class="title">检修次数</div>
        </div>
        <div class="itemBottom">
          <RepairCount :type="type" :time="repairData[0]" :echartData="repairData[1]"></RepairCount>
        </div>
      </div>
      <div class="item">
        <div class="itemTop">
          <div class="title">故障统计</div>
        </div>
        <div class="itemBottom">
          <FaultType :id="elevotor.id"></FaultType>
        </div>
      </div>
      <div class="item">
        <div class="itemTop">
          <div class="title">告警统计</div>
        </div>
        <div class="itemBottom">
          <ElevatorStopRecord :id="elevotor.id"></ElevatorStopRecord>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RunDegree from "./RunDegree.vue";
import RepairCount from "./RepairCount.vue";
import ElevatorStopRecord from "./ElevatorStopRecord.vue";
import FaultType from "./FaultType.vue";
export default {
  components: { RunDegree, RepairCount, ElevatorStopRecord, FaultType },
  data() {
    return {
      loading: false,
      echartData: [],
      repairData: [],
      elevotor: {},
      type: "1"
    };
  },

  mounted() {
    this.getData(this.$route.params.id);
    this.getEchartsData(this.$route.params.id);
    this.getEchartsRepair(this.$route.params.id);
    this.type = this.$route.params.type;
  },

  methods: {
    // 根据id获取详细信息
    getData(id) {
      this.loading = true;
      this.$http.get(`periodicInspectionBill/${id}`).then((res) => {
        this.elevotor = res.data;
        this.loading = false;
      });
    },

    // 运行次数
    getEchartsData(id) {
      this.$http.get(`periodicInspectionBill/count/run/${id}`).then((res) => {
        this.echartData = res.data;
      });
    },

    // 检修次数
    getEchartsRepair(id) {
      this.$http.get(`periodicInspectionBill/count/repair/${id}`).then((res) => {
        this.repairData = res.data;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  padding: 0px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #F2F2F2;

  .top {
    // background-color: pink;
    display: flex;
    padding: 10px 0;

    .left {
      flex: 1;
    }

    .right {
      width: 300px;
    }
  }

  .elevatorInfo {
    line-height: 32px;

    .link {
      float: right;
      width: 96px;
      height: 32px;
      background-color: #0747FD;
      border-radius: 8px 8px 8px 8px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      // margin-top: 12px;
    }
  }

  .echarts {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 49.6%;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      margin: 8px 0;
      box-sizing: border-box;
      position: relative;

      .itemTop {
        height: 48px;
        border-bottom: 1px solid #F2F2F2;
        display: flex;
        align-items: center;
        padding-left: 16px;

        .title {
          border-left: 4px solid #0747FD;
          padding-left: 8px;
          color: #4D4D4D;
        }
      }

      .itemBottom {
        position: absolute;
        top: 48px;
        bottom: 0;
        right: 20px;
        left: 20px;
        flex: 1;
      }
    }
  }
}
</style>