<template>
  <div style="height: 100%">
    <el-empty v-if="isShow" description="暂无记录" style="width: 100%; height: 100%"></el-empty>
    <div v-else id="echartOne" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    time: {
      type: Array,
      default: () => {
        return [];
      },
    },
    echartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "1"
    }
  },

  watch: {
    time: {
      handler(val) {
        if (val) {
          let result = this.echartData.some(item => item);
          if (!result) {
            this.isShow = true;
          } else {
            this.initCharts();
          }
        }
      }
    }
  },

  data() {
    return {
      isShow: false,
    };
  },

  methods: {
    initCharts() {
      var that = this;
      let myChart = that.$echarts.init(document.getElementById("echartOne"));
      let option = {
        title: {
          subtext: "单位：次"
        },
        tooltip: {},
        xAxis: {
          data: that.time,
          axisLine: {
            lineStyle: {
              color: "#808080"
            }
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            data: that.replaceMax(that.echartData),
            type: "bar",
            barWidth: that.type == 1 ? 50 : 20,
            itemStyle: {
              color: "#F2F5FF",
            },
            label: {
              show: true,
              position: "start",
              rich: {
                line: {
                  backgroundColor: "#0747FD",
                  fontSize: 2,
                  width: that.type == 1 ? 50 : 20
                },
                maxLine: {
                  backgroundColor: "#F1A118",
                  fontSize: 2,
                  width: that.type == 1 ? 50 : 20
                },
              },
              formatter: function (params) {
                var max = Math.max(...that.echartData);
                if (params.value == max) {
                  return "{maxLine|}";
                } else if (params.value) {
                  return "{line|}";
                } else {
                  return "";
                }
              }
            },
            emphasis: {
              itemStyle: {
                color: "#2E64FD" // 设置鼠标移入时的背景颜色
              }
            },
            markLine: {
              data: [{
                type: "average"
              }],
              silent: true,
              symbol: "none",
              precision: 0,
              lineStyle: {
                color: "#B4C7FF",
                type: "solid",
                width: 2
              }
            }
          },
        ]
      };
      myChart.setOption(option);
    },

    // 给最大值单独设置颜色
    replaceMax(arr) {
      let newArr = JSON.stringify(arr);
      let returnArr = JSON.parse(newArr);
      var max = Math.max(...returnArr);
      returnArr.forEach((item, index) => {
        if (item === max) {
          returnArr[index] = {
            value: item,
            itemStyle: {
              color: "#FFFAF3"
            }
          };
        }
      });
      return returnArr;
    }
  },
};
</script>
