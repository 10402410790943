<template>
  <div style="height: 100%">
    <el-empty v-if="isShow" description="未见故障" style="width: 100%; height: 100%"></el-empty>
    <div v-else style="height: 100%">
      <div id="main" style="height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  watch: {
    id(newVal, oldVal) {
      this.getData(newVal);
    },
  },

  data() {
    return {
      echartData: [],
      isShow: false,
    };
  },

  methods: {
    getData(id) {
      this.$http.get(`periodicInspectionBill/count/faultType/${id}`).then((res) => {
        this.echartData = res.data;
        if (this.echartData.length) {
          this.initCharts();
          this.isShow = false;
        } else {
          this.isShow = true;
        }
      });
    },

    initCharts() {
      let myChart = this.$echarts.init(document.getElementById("main"));
      let option = {
        legend: {
          bottom: 20,
          left:0
        },
        color: ["#0747FD", "#7194FC ", "#84D6FF", "#00D09C", "#FDBD50", "#FF7E48"],
        series: [
          {
            type: "pie",
            radius: ["40%", "55%"],
            data: this.echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              formatter: "{c}次({d}%)",
              rich: {
                per: {
                  color: "#fff",
                  backgroundColor: "#4C5058",
                  padding: [2, 4, 4, 4],
                  borderRadius: 4,
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
